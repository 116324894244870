body {
    color: #333841;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
    margin: 0;
    padding: 0;
    /*background: #f3f3f3 url("/img/bg_noise.jpg");*/
    /*background-color: #f0f0f0;*/
}

footer {
  z-index: 1;
}

footer h4 {
    margin-bottom: 1rem;
}
footer h4 span {
    border-bottom: 2px solid #F95028;
    padding: 0.5rem 1rem 0.5rem 0;
    font-size: 1.125rem;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar appears above other content */
  background-color: #182868; /* Set the background color of the navbar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a raised effect */
}

.navbar-brand {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  box-sizing: 'border-box';
  justify-content: 'space-between';
  width: 260px;
}

.menuitem {
  background-color: #182868; /* Set the background color of the navbar */
}

.maincontent {
  margin-top: 80px;
  margin-left: 260px;
}

.table td, .table th, .table td a {
  font-size: 90%;
  padding-inline: var(--chakra-space-1);
}

.table th {
  font-weight: 600;
  text-transform: none;
  color: var(--chakra-colors-black);
}

.invoicerows tbody td {
  padding-left: var(--chakra-space-1);
  font-size: var(--chakra-fontSizes-sm);
}

.invoicerows tbody input, .invoicerows tbody select {
  font-size: var(--chakra-fontSizes-sm);
  padding: 0 var(--chakra-space-1) 0 var(--chakra-space-1);
}
